<template>
  <div>
    <div class="card" @click="showMsg">
      <div class="line-1">
        <div>{{ msg.NoticeTitle }}</div>
        <div>
          <van-tag
            type="warning"
            color="#f2f2f3"
            v-if="msg.IsRead"
            text-color="gray"
            size="medium"
            >已读</van-tag
          >
          <van-tag type="danger" v-else size="medium">未读</van-tag>
        </div>
      </div>
      <div class="line-2">
        <div>级别：{{ msg.NoticeLevel }}</div>
        <div>通知时间：{{ msg.NoticeTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'msgCard',
  props: {
    msg: {
      type: Object,
      default() {
        return {
          NoticeId: '0000',
          NoticeTitle: '标题AAAAA',
          NoticeLevel: '省级别',
          IsRead: false,
          NoticeTime: '2022-05-29 12:12:12',
        }
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    showMsg() {
      // alert(1)
      this.$emit('toDetail', this.msg.NoticeId)
    },
  },
}
</script>

<style scoped lang="less">
.card {
  //color: red;
  margin: 5px 10px;
  border: 1px solid rgba(128, 128, 128, 0.39);
  border-radius: 5px;
  padding: 10px;
  font-family: siyuan, PingFang SC, sans-serif;
  .line-1 {
    display: flex;
    justify-content: space-between;
  }
  .line-2 {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    //padding:0.3rem 0;
    padding-bottom: var(0.3rem - 0.05rem);
    padding-top: 0.3rem;
    div:nth-child(2) {
      color: gray;
    }
  }
}
</style>
